@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css");

$color-A1: #FFEDCC;
$color-A2: #f8b3aa;

$color-B1: #ae9ae1;
$color-B2: #190960;

$color-message-ok-background: #6346A3;
$color-message-warning-background: #d60b15;

$color-warn: #dc362e;

$base-body-background: #141414;
$global-primary-background: #313131;
$global-secondary-background: $color-A1;

$navbar-background: #141414;
$navbar-nav-item-active-color: #d0d0d0;
$navbar-nav-item-color: #a0a0a0;

$logo-color: #fff;
$logo-hover-color: #fff;

$base-body-color: #b5b5b5;
$dropdown-color: $base-body-color;

$base-link-color: #fff;
$base-link-hover-color: #fff;
$base-link-hover-text-decoration: none;

$icon-link-color: #ccc;
$icon-link-hover-color: #fff;

$form-background: rgba(80, 80, 80, 0.7);
$form-focus-background: rgba(50, 50, 50, 0.4);
$form-disabled-background: #222222;
$form-disabled-color: #b8ab94;
$form-icon-color: #fff;
$form-icon-hover-color: #fff;
$form-radio-focus-border: #cccccc;
$form-radio-checked-background: $color-A1;
$form-radio-checked-focus-background: $color-A1;

$grid-divider-border: #818181;

$global-font-family: 'Pretendard';

$global-small-box-shadow: 0 2px 8px rgba(128, 128, 128, 0.08) !default;
$global-medium-box-shadow: 0 5px 15px rgba(128, 128, 128, 0.08) !default;
$global-large-box-shadow: 0 14px 25px rgba(128, 128, 128, 0.16) !default;
$global-xlarge-box-shadow: 0 28px 50px rgba(128, 128, 128, 0.16) !default;

$base-heading-color: #ffffff;


@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/mixins-theme.scss";
@import "../node_modules/uikit/src/scss/uikit-theme.scss";
@import "./app/common.scss";

body.no-scroll {
    overflow: hidden !important;
}

@mixin search-form {
    border-radius: 100px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.4);
    border-style: solid;
}

body,
div,
button,
textarea,
input {
    font-family: 'Pretendard';
}

:root {
    --marquee-height: 180px;
    --marquee-elements-displayed: 5;
    --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
    --marquee-animation-duration: calc(var(--marquee-elements) * 3s);

    --partner-marquee-elements-displayed: 6;
    --partner-marquee-element-width: calc(var(--partner-marquee-width) / var(--partner-marquee-elements-displayed));
    --partner-marquee-animation-duration: calc(var(--partner-marquee-elements) * 3s);
}

body {
    overflow-x: hidden;
    overflow-y: overlay;
}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-thumb {
    background: #818181;
    border-radius: 10px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.common-horizontal-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.common-vertical-alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.common-button {
    border-radius: 2px;
    color: #F4F4F4;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #FFA600;
    padding: 0 24px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.common-button1 {
    @extend .common-button;
    background-color: #FFA600;
    border: 1px solid transparent;
}

.common-button1:hover {
    background: rgba(255, 166, 0, 0.7);
    border: 1px solid transparent;
}

.common-button1:focus {
    background-color: #ffb833;
    border: 1px solid transparent;
}

.common-button1:disabled {
    background-color: #424242;
    color: #616161;
}

.common-button2 {
    @extend .common-button;
    background: rgba(255, 247, 233, 0.1);
    color: #FFA600;
}

.common-button2:hover {
    background: rgba(255, 219, 153, 0.05);
    color: rgba(255, 166, 0, 0.7);
}

.common-button2:focus {
    background: rgba(255, 237, 204, 0.2);
}

.common-button2:disabled {
    background: rgba(255, 247, 233, 0.1);
    color: #616161;
    border: 1px solid #616161;
    cursor: auto;
}

.reference-mark {
    font-family: 'Roboto';
}


.common-button3 {
    @extend .common-button;
    background-color: #7e8b98;
    border: 1px solid transparent;
    color: white;
}

.common-button3:hover {
    background-color: #98a4ae;
    border: 1px solid transparent;
}

.common-button3:focus {
    background-color: #98a4ae;
    border: 1px solid transparent;
}

.already-purchased-notice-bg {
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
}

.already-purchased-notice {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 386px;
    height: 49px;
    background-color: #141414;
    border-radius: 5px;
    font-size: 14px;
    line-height: 19px;

    div:nth-child(1) {
        padding-left: 20px;
        color: #F5F5FD;
    }

    div:nth-child(2) {
        padding-right: 33px;
        color: #FFA600;
        cursor: pointer;
    }
}

.modifyuser-popup-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2A2A2A;
    width: auto;
    border-radius: 15px;
    padding: 25px 89px 25px 89px;
    line-height: 24px;
    text-align: center;

    @extend .common-vertical-alignment;

    div:nth-child(2) {
        margin-bottom: 21px;
    }

    .common-button {
        font-size: 12px !important;
        line-height: 32px;
        padding: 0px 40px 0px 40px;
    }
}


.search-form {
    @include search-form;
}

.popup-shadow {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.6);
}

.search-dropdown {
    background-color: rgb(42, 42, 42);
    padding: 15px 10px 15px 30px;
    border-radius: 0 0 20px 20px;
    min-width: 180px;
}

.search-text {
    color: $color-A1;
    background-color: #2a2a2a;
}

.search-category {
    color: #FFA600;
}

.item-background {
    background: linear-gradient(#494949, $global-primary-background);
}

.item-background-inner {
    width: 300px;
    height: 400px;
    background-size: 120%;
}

.item-hover-background {
    background: linear-gradient(#ffffff, #c0c0c0);
    border-radius: 20px;
    position: absolute;
    top: 0;
    transform-origin: center top;
    transform: scale(1.2);
    color: #888;
    display: none;
    z-index: 10;
    width: 300px;
    height: 400px;
}

.item-hover-background-inner {
    width: 100%;
    height: 100%;
    background-size: 125%;
    background-position: 50% 20%;
    background-repeat: no-repeat;
}

.item-hover-info {
    margin-bottom: 50px;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 8px 10px;
    border-radius: 10px;
    min-width: 130px;
    color: white;
    font-size: 10px;
    font-weight: 200;
}

.item-popup-background {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 1200;
}

.item-popup-viewer {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px 0 0 20px;
    width: 55%;
    height: 100%;
    background-image: url(/assets/image/merror_viewer_background.png);
    background-size: cover;
    background-position: center;
}



.item-popup-viewer>canvas {
    width: 100%;
    height: 100%;
    border-radius: 20px 0 0 20px;
}

.item-popup-viewer-menu1-background {
    background-color: #141414;
    border-radius: 10px;
    padding: 10px 0;
    width: 180px;
    min-width: 180px;
}

.item-popup-viewer-menu1-background>div {
    font-size: 12px;
    width: 165px;
    height: 40px;
    padding-left: 15px;
    position: relative;
    transition: all 0.3s;
}

.item-popup-viewer-menu1-background>div:hover {
    background-color: #535353;
}

.item-popup-viewer-menu1-background>div>span {
    padding: 11px 0;
    display: block;
}

.item-popup-viewer-menu1-indicator {
    position: absolute;
    left: 0px;
    width: 4px;
    height: 40px;
    background-color: orange;
    content: "\00a0";
    opacity: 0;
    transition: opacity 0.3s;
}


.item-popup-title-outer {
    width: 527px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 72px;
    margin-bottom: 70px;
}

.item-popup-title-inner {
    display: flex;
    align-items: center;
}

.item-popup-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
}

.item-popup-button-common {
    border-radius: 50px;
}

.item-popup-button-cart {
    @extend .item-popup-button-common;
    background-color: #818181;
}

.item-popup-button-buy {
    @extend .item-popup-button-common;
    background-color: #6249a1;
}

.item-popup-model-info-bg {
    width: 527px;
    border-radius: 10px;
    background-color: #2a2a2a;
    margin-left: auto;
    margin-right: auto;


    @media only screen and (max-width: $mobile-max-width) {
        font-size: setSize(12);
    }
}

.item-popup-model-info-row {
    height: 39px;
    padding-left: 22px;
}

.item-popup-model-info-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-popup-model-info-cell-first {
    margin: auto;
    text-align: left;
}

.signup-background {
    background-color: #202020;
    border-radius: 5px;
}

.signup-inner>app-input>div {
    padding: 0 !important;
    min-height: 75px !important;
}

.signup-inner>app-input>div>div:first-child {
    padding: 0 !important;
    height: 46px;
    display: flex;
    align-items: center;
}

.signup-inner>app-input>div>div:first-child>input {
    width: 100% !important;
    height: 100%;
    color: #fff;
}

.signup-inner>app-input>div>div:first-child>img {
    margin-right: 2px;
    width: 39px;
    height: 39px;
}

.signup-inner>app-input>div>.signup-input-description {
    text-align: left !important;
    font-weight: 400;
    font-size: 12px;
    color: #9E9E9E;
    padding-left: 8px;
}

.signup-input-border {
    background: rgba(255, 247, 233, 0.1);
    border: 1px solid #BDBDBD;
    border-radius: 2px;
    color: #7E8B98;
    text-align: center;
    transition: all 0.6s;
}

.signup-input-description {
    font-size: 12px;
    margin-top: 3px;
    color: #9E9E9E;
    padding-left: 8px;
    transition: all 0.6s;
}

.signup-input {
    background-color: transparent;
    border: none;
    height: 35px;
    margin-left: 30%;
    width: 70%;
    color: #818181;
}

.signup-input::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #616161;
}

.signup-input:focus {
    background-color: transparent;
    color: #bbb;
}

.signup-input-desc {
    transform-origin: 0 0;
    transition: all 0.3s;
    padding: 10px 18px;
    pointer-events: none;
}

.hellomessage {
    color: #FFA600;
}

.freecoupon {
    color: #FFA600;
}

.deals-header-empahsis {
    color: rgba(255, 219, 153, 1);
}

.free-empahsis {
    color: #E4F8C7;
}

.icon1>svg {
    padding-bottom: 2px;
}

.mainmenu-hover {
    position: absolute;
    top: 8px;
    left: 90px;
    pointer-events: none;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.8);
    color: $color-A1;
    width: 100px;
    padding: 4px 0 0;
    font-size: 0.9rem;
    text-align: center;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    display: none;
}

.comment_link {
    font-size: 12px;
    margin-top: 15px;
}

.comment_link>a {
    color: #FFA600;
}

.showmessage-bg {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 100%;
    height: 70px;
    background-color: $color-message-ok-background;
    pointer-events: none;
    transition: opacity 0.4s;
    opacity: 0;
}

.showmessage-bg-ok {
    @extend .showmessage-bg;
    background-color: $color-message-ok-background;
}

.showmessage-bg-warn {
    @extend .showmessage-bg;
    background-color: $color-message-warning-background;
}

.showmessage-content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    font-size: 1.1rem;
}

.color-primary1 {
    color: $color-A1;
}

.color-primary2 {
    color: $color-A2;
}

.color-secondary1 {
    color: $color-B1;
}

.color-secondary2 {
    color: $color-B2;
}

.color-secondary3 {
    color: #B7BEC5;
}

.color-warn {
    color: $color-warn !important;
}

.border-warn {
    border: $color-warn solid 1px !important;
}


.default-title {
    margin-bottom: 10px;
}

.mo-default-title {
    margin: setSize(25.5) setSize(20);
}

.default-title-text {
    font-size: 22px;
    font-weight: 600;
    color: white;
}

.mo-default-title-text {
    font-size: setSize(17);
    font-weight: 700;
}

.default-title-count {
    font-size: 12px;
    font-weight: 400;
    color: white;
    padding-left: 5px;
}

.eula-outer {
    background-color: $global-primary-background;
    color: white;

}

.eula-title {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    padding-top: 116px;
}

.eula-divider {
    width: 80%;
    max-width: 1000px;
    margin: 50px auto;
    background-color: #d3d7dc;
    height: 2px;
}

.eula-content-outer {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 0 200px 0;
}

.eula-subtitle0 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 48px;
}

.eula-subtitle1 {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    padding-top: 30px;
}

.eula-content {
    font-weight: 500;
    line-height: 25px;
    font-size: 14px;
    padding-top: 10px;
}

.search-outer {
    width: 100%;
    position: relative;

}

.search-inner {
    width: 100%;
    height: 38px;
    background-color: rgba(211, 211, 211, 0.2);
    border: 0.5px solid #141414;
    border-radius: 20px;
    margin: auto;
    text-align: center;
    color: #D3D7DC;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
}


.search-inner>img {
    margin-left: 20px;
}

.search-inner>span {
    margin-left: 20px;

    @media only screen and (max-width: 1000px) {
        display: none;
    }
}

.search-input {
    @extend .uk-input;
    @extend .uk-text-center;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: #999;
}

.search-input:focus {
    background-color: rgba(0, 0, 0, 0);
    color: #999;
}

.search-input::placeholder {
    color: $color-A1;
    font-weight: 500;
    font-size: 16px;
}

.modal-background {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #403E3B;
    z-index: 1001;
}

.modal-background-alert-popup {
    @extend .modal-background;
    background-color: rgba(0, 0, 0, 0.4);
}

.modifyuser-popup-outer {
    margin: 0;
    padding: 56px 48px 53px 54px;
    background-color: $global-primary-background;
    border-radius: 20px;
    min-width: 580px;
}

.empty-container,
.error-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 6.1rem auto;
    position: relative;
    color: #F4F4F4;
}

.empty-desc {
    font-size: 20px;
    font-weight: 400;
    margin-top: 40px;
    color: #F4F4F4;

}

.error-desc {
    @extend .empty-desc;
    text-align: center;
    margin-top: 0px;
    position: absolute;
    top: 170px;
}

.empty-icon {
    margin-bottom: 20px;
}

.empty-button {
    width: 173px;
    height: 43px;
    font-weight: 600;
    font-size: 16px;
    margin-top: 45px;
}

.error-button {
    @extend .empty-button;
    width: 202px;
    margin-top: 50px;
}

.empty-cart-button:hover {
    background-color: #FFA600;
    border: none;
}

.error-img {
    width: 200px;
    height: 200px;

    @media only screen and (max-width: $mobile-max-width) {
        width: setSize(200);
        height: setSize(200);
    }
}


input[type="checkbox"]+label {
    display: block;
    width: 20px;
    height: 20px;
    background: url('^./assets/icon/checkbox_24.svg') no-repeat 0 0px / contain;
}

input[type='checkbox']:checked+label {
    width: 20px;
    height: 20px;
    background: url('^./assets/icon/checkbox_checked_24.svg') no-repeat 0 0px / contain;
}

input[type="checkbox"] {
    display: none;
}

input[type='checkbox']:disabled+label {
    width: 20px;
    height: 20px;
    background: url('^./assets/icon/checkbox_disabled_24.svg') 0px / cover;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]+label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/assets/icon/radio_24.svg') no-repeat 0 0px / cover;
}

input[type="radio"]:checked+label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/assets/icon/radio_checked_24.svg') no-repeat 0 0px / cover;
}

.main-version {
    position: fixed;
    top: 50px;
    left: 5px;
    color: orange;
    z-index: 9999;
    pointer-events: none;
}

.password-reset-notice-wrap>app-input>div {
    width: 280px;
    padding: 0px 0px !important;
    min-height: 75px !important;

    @media only screen and (max-width: $mobile-max-width) {
        width: setSize(318);
        min-height: 0 !important;
    }
}

.password-reset-notice-wrap>app-input>div>div:first-child {
    padding: 0px 0px !important;
    height: 44px;
}

.password-reset-notice-wrap>app-input>div>div:first-child>input {
    font-size: 14px;
    width: 100% !important;
    height: 100%;
    color: #fff;

    @media only screen and (max-width: $mobile-max-width) {
        font-size: setSize(14);
    }
}

.password-reset-notice-wrap>app-input>div>.signup-input-description {
    text-align: left !important;
    font-size: 11px !important;
}

.item-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
}

.item-list-wrap {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: auto;
    width: 100%;
    display: grid;
    grid-gap: 20px;
    justify-items: center;
}

.item-type-posed {
    background: #CF1616;
}

.item-type-facial {
    background: rgba(17, 126, 34, 0.3);
}

.item-type-rigged {
    background: rgba(0, 170, 160, 0.5);
}

.item-type-A-Posed {
    background: #FA6800;
}

.ghost-page {
    height: 525px;
    background-color: #313131;

    @media only screen and (max-width: $mobile-max-width) {
        height: setSize(560);
    }
}

.password-popup-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.password-popup-title {
    font-weight: 700;
    font-size: 22px;
    color: white;
    text-align: center;
    margin-top: 19px;

    @media only screen and (max-width: $mobile-max-width) {
        display: none;
    }
}

.password-popup-notice {
    color: #EFF1F2;
    line-height: 19px;
    margin: 28px 0 19px 0;
    text-align: center;

    @media only screen and (max-width: $mobile-max-width) {
        margin: setSize(30) 0 setSize(63) 0;
    }
}

.password-input-outer {
    @extend .signup-input-border;
    display: flex;
    position: relative;
    box-sizing: border-box;
    vertical-align: middle;
    width: 100%;
    height: 49px;

    @media only screen and (max-width: $mobile-max-width) {
        height: setSize(38);
    }

    input {
        box-sizing: border-box;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #FFFFFF;
        outline: none;
        padding-left: 14px;

        @media only screen and (max-width: $mobile-max-width) {
            padding-left: setSize(10);
        }
    }


}

.password-input-outer>input::-ms-reveal {
    margin-right: 10px;
}

.password-popup-button-wrap {
    width: 280px;
    margin: 20px auto 0px auto;


    @media only screen and (max-width: $mobile-max-width) {
        width: 100%;
    }


    button {
        width: 100%;
        height: 49px;

        @media only screen and (max-width: $mobile-max-width) {
            height: setSize(35);
            border-radius: 3px;
            font-size: setSize(14);
        }
    }

    button:first-child {
        margin-bottom: 10px;
    }
}

.password-popup-input-msg {
    font-size: 12px;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $mobile-max-width) {
        height: setSize(30);
        font-size: setSize(12);
    }
}

.password-popup-warn {
    font-weight: 400;
    font-size: 12px;
    color: $color-warn;
    transition: all 0.8s;

    @media only screen and (max-width: $mobile-max-width) {
        font-size: setSize(12);
    }
}

.password-popup-inner {
    margin-top: 9px;
    font-weight: 400;
    font-size: 14px;
    color: white;
    width: 280px;
}

.mo-popup-nav {
    display: none;

    @media only screen and (max-width: $mobile-max-width) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        background: #403E3B;
        position: relative;
    }
}

.mo-popup-nav-back {

    @media only screen and (max-width: $mobile-max-width) {
        width: setSize(48);
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
    }
}

.mo-popup-nav-title {

    @media only screen and (max-width: $mobile-max-width) {
        color: #F4F4F4;
        font-weight: 700;
        font-size: 16px;
        padding: 0;
        margin: 0;
    }

}

.deals-tablet-br {
    display: none;


    @media all and (max-width: $tablet-max-width) {
        display: block;
    }
}

.tablet-br {
    display: none;

    @media all and (max-width: $tablet-max-width) {
        display: block;
    }
}

.banner-br {
    display: none;

    @media all and (max-width: 700px) {
        display: block;
    }
}

.mobile-br {
    display: none;

    @media all and (max-width: $mobile-max-width) {
        display: block;
    }
}

.datatype-smalltext {
    font-size: 10px;
    display: inline-block;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.admin-assetinfo-outer {
    width: 100%;
    height: 160px;
    font-size: 14px;
    margin-bottom: 5px;
}

.admin-assetinfo-canvas {
    width: 160px;
    height: 160px;
    float: left;
}

.admin-assetinfo-item {
    width: 180px;
    height: 100%;
    float: left;
    margin: 0 15px;
}

.dots-1 {
    width: 14px !important;
    border: none !important;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #fff 90%, #0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: d1 1s steps(4) infinite;
    margin-top: 0.5em;
}

.bottom-box {
    @media only screen and (max-width: $mobile-max-width) {
        margin-bottom: setSize(100) !important;
    }

}

@keyframes d1 {
    to {
        clip-path: inset(0 -34% 0 0)
    }
}

.testelement {
    background-color: tomato !important;
    color: yellow !important;
}


.fb-marquee {
    width: 100%;
    height: 100px;
    overflow: hidden;
    position: relative;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        height: 78px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        height: 56px;
    }
}

.fb-marquee-content {
    // border: 2px solid green;
    list-style: none;
    height: 100%;
    display: flex;
    animation: scrolling var(--marquee-animation-duration) linear infinite;
    padding: 0;
    margin: 0;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        animation: scrolling2 var(--marquee-animation-duration) linear infinite;
    }

    @media only screen and (max-width: $mobile-max-width) {
        animation: scrolling3 var(--marquee-animation-duration) linear infinite;
    }
}

.fb-marquee-content:hover {
    animation-play-state: paused;
}

@keyframes scrolling {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 * 2981px));
    }
}

@keyframes scrolling2 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 *2505px));
    }
}

@keyframes scrolling3 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 *1837px));
    }
}



.fb-marquee_inner {
    padding-right: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
    flex-shrink: 0;
    width: auto;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        padding-right: 52px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        padding-right: 40px;
    }
}

.fb-partner-icon {
    width: 100%;
}

.fb-marquee_inner-0 {
    width: 162px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 140px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 100px
    }
}

.fb-marquee_inner-1 {
    width: 149px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 127px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 87px
    }
}

.fb-marquee_inner-2,
.fb-marquee_inner-3 {
    width: 163px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 141px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 101px
    }
}

.fb-marquee_inner-4 {
    width: 153px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 131px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 91px
    }
}

.fb-marquee_inner-5 {
    width: 158px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 136px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 96px
    }
}

.fb-marquee_inner-6 {
    width: 189px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 167px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 127px
    }
}

.fb-marquee_inner-7 {
    width: 187px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 165px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 125px
    }
}

.fb-marquee_inner-8,
.fb-marquee_inner-9,
.fb-marquee_inner-12 {
    width: 83px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 61px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 41px
    }
}

.fb-marquee_inner-10,
.fb-marquee_inner-13 {
    width: 161px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 139px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 99px;
    }
}

.fb-marquee_inner-11 {
    width: 190px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        width: 168px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        width: 128px;
    }
}


.couponbenefits {
    color: #FFA600;
}

.couponlocation {
    text-decoration: underline;
}

.stripbanner-empahsis {
    font-weight: 800;
}

.text-border {
    border-bottom: 2px solid #f4f4f4;
    padding-bottom: 16px;

    @media all and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        padding-bottom: 12px;
    }

    @media only screen and (max-width: $mobile-max-width) {
        padding-bottom: 8px;
    }
}


@keyframes textIn {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes textOut {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);

    }
}


@keyframes partnerScrolling {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 * 1242px));
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        transform: translateY(20px);

    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}